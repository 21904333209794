import { Table } from 'antd';

import { CompanyUsage, Plan } from '../../generated-api';

type Props = {
  usage: CompanyUsage | undefined;
  plan: Plan;
  loading: boolean;
};

const FeeTable = ({ loading, plan, usage }: Props) => {
  const usageAvailable = usage !== undefined;
  const fee = plan.fee;
  const baseFee = fee[0].base ?? 0;

  const dataSource = [
    {
      description: '基本料金',
      value: usageAvailable ? baseFee.toLocaleString() + '円' : '-',
      key: '0',
    },
  ];
  if (plan && fee.length > 0) {
    if (fee.length === 1) {
      const minThisTier = fee[0].minimum_page;
      const usageThisTier = Math.max(usage?.usage ?? 0 - minThisTier, 0);
      const rate = fee[0].rate;
      const billThisTier = usageThisTier * rate;
      const desc = `月${minThisTier + 1}枚以上の利用料金（1枚${rate}円）`;
      dataSource.push({
        description: desc,
        value: usageAvailable ? `${billThisTier.toLocaleString()}円` : '-',
        key: '1',
      });
    } else {
      for (let i = 0; i < fee.length; ++i) {
        const minThisTier = fee[i].minimum_page;
        const maxThisTier = i === fee.length - 1 ? Number.MAX_SAFE_INTEGER : fee[i + 1].minimum_page - 1;
        const usageThisTier = Math.max(Math.min(usage?.usage ?? 0, maxThisTier) - minThisTier, 0);
        const rate = fee[i].rate;
        const billThisTier = usageThisTier * rate;
        const desc =
          i === 0
            ? `月${maxThisTier + 1}枚までの利用料金（1枚${rate}円）`
            : `月${minThisTier + 1}枚以上の利用料金（1枚${rate}円）`;
        dataSource.push({
          description: desc,
          value: usageAvailable ? `${billThisTier.toLocaleString()}円` : '-',
          key: (i + 1).toString(),
        });
      }
    }
  }

  if (usage?.over_billing) {
    dataSource.push({
      description: '過剰請求分ご返金',
      value: `-${usage.over_billing.toLocaleString()}円`,
      key: 'over_billing',
    });
  }

  const columns = [
    {
      dataIndex: 'description',
      width: 130,
    },
    {
      dataIndex: 'value',
      width: 10,
    },
  ];

  return (
    <Table
      bordered={true}
      columns={columns}
      dataSource={dataSource}
      loading={loading}
      pagination={false}
      showHeader={false}
    />
  );
};

export default FeeTable;
